<template>
  <v-card>
    <v-card-title> Price Lists </v-card-title>
    <v-card-text>
      <v-btn color="success" class="mr-2" @click.stop="downloadPriceList()">
        Download Giclee Price List
        <v-icon class="ml-4">
          mdi-download
        </v-icon>
      </v-btn>
      <!-- <v-radio-group
      v-model="type"
      row
      @change="setType"
    >
      <v-radio
        label="Artists Brochure"
        value="artist"
      ></v-radio>
      <v-radio
        label="Regional Brochure"
        value="region"
      ></v-radio>
      <v-radio
        label="Latest Images"
        value="latest"
      ></v-radio>
    </v-radio-group>
      <div class="d-flex mt-4" v-if="filteredGiclees && type === 'artist'">
        <v-autocomplete
          class="mr-2"
          v-model="selectedArtist"
          :items="artists"
          outlined
          clearable
          clear-icon="mdi-close-box"
          dense
          item-text="artist"
          item-value="artistCode"
          :label="'Artist'"
        ></v-autocomplete>
      </div>
      <div class="d-flex mt-4" v-if="regions.length && type === 'region'">
        <v-autocomplete
          class="mr-2"
          v-model="selectedRegion"
          :items="regions"
          outlined
          clearable
          clear-icon="mdi-close-box"
          dense
          :label="'Regions'"
        ></v-autocomplete>
      </div>
      <v-btn v-if="selectedArtist" color="success" @click.stop="downloadCatalog(selectedArtist)">
          Download Artist Brochure
          <v-icon class="ml-4">
            mdi-download
          </v-icon>
      </v-btn> -->
      <v-card-text v-if="loading" class="mt-4 text-center">
        <h2 class="mt-4 mb-4 text-center">
          Please wait while we generate the document
        </h2>
        <v-progress-circular
          indeterminate
          :size="70"
          :width="7"
          color="green"
        ></v-progress-circular>
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
import ArtistApi from "@/api/admin/artist";
import ArtworkApi from "@/api/admin/artwork";
import ProductApi from "@/api/admin/product"

export default {
  data() {
    return {
      type: null,
      imageUrl: process.env.VUE_APP_IMG_URL,
      selectedArtist: null,
      selectedRegion: null,
      regionalGiclees: null,
      artistGiclees: null,
      giclees: [],
      loading: false,
      headers: [
        { text: "Current Image", value: "artworkImage" },
        { text: "New Image", value: "thumb" },
        { text: "Title", value: "title" },
        { text: "Artwork", value: "artworkCode" },
        { text: "Sales", value: "sales" },
        { text: "Giclee Brochure", value: "gicleeBrochure" }
      ],
    };
  },
  async created() {
    ArtistApi.loadRegionalGiclees().then((res) => {
      this.regionalGiclees = res.data
    })
    ArtistApi.loadArtistGiclees({ artistCodes: [] }).then((res) => {
      this.artistGiclees = res.data;
    });
  },
  computed: {
    filter() {
      const obj = {};
      this.selectedArtist && (obj.artistCode = this.selectedArtist);
      this.selectedRegion && (obj.region = this.selectedRegion);
      return obj;
    },
    filteredGiclees() {
        return this.selectedArtist || this.selectedRegion ? _.filter(this.giclees, this.filter)[0] : [];
    },
    artists() {
      return _.orderBy(_.uniq(
        this.giclees.map((a) => {
          return { artist: a.artist, artistCode: a.artistCode };
        })
      ), ['artist'],['asc'] )
    },
    regions() {
      return this.giclees.map((a) => {
        return a.region
      }
    )},
  },
  methods: {
    async setType(evt) {
      this.giclees = []
      switch (evt) {
        case 'region' :
          this.selectedArtist = null
          this.giclees = this.regionalGiclees
        break
        case 'artist' :
          this.selectedRegion = null
          this.giclees = this.artistGiclees
        break
      }
    },
    async downloadCatalog(artistCode) {
      this.loading = true
      ProductApi.getCatalogPDF({artistCodes: artistCode ? [artistCode] : []}).then((results) => {
        this.loading = false
      })
    },
    async downloadPriceList() {
      this.loading = true
      ProductApi.getPriceListPDF().then((results) => {
        this.loading = false
      })
    },
    async setGicleeBrochure ({artworkCode, gicleeBrochure}) {
      ArtworkApi.updateArtwork({ artworkCode, gicleeBrochure }).then((res) => {
      console.log(res)
    });
    console.log(value)
    }
  }

};
</script>
